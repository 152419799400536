
































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ArticleProperty } from "@/types/shop/article";

/**
 * ArticlePropertyConfiguration component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class ArticlePropertyConfiguration extends Vue {
    @Prop({ type: Array, required: true })
    readonly properties!: ArticleProperty[];

    @Prop({ type: Object, required: true })
    readonly value!: ArticleProperty;

    @Prop({ type: Boolean, default: false }) readonly showApplyButton!: boolean;

    private applyValue = this.value;

    /**
     * Function to change property value
     * Emits input event
     *
     * @param value value
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private changePropertyValue(value: string) {
        const newValue = this.value.value !== null ? {
            ...this.value.value,
            value: parseFloat(value)
        } : null;

        this.applyValue = {
            ...this.value,
            value: newValue
        };

        this.$emit("input", { ...this.applyValue });
    }

    /**
     * Function to change property
     * Emits input event
     *
     * @param property property
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private changeProperty(property: ArticleProperty) {
        this.$emit("input", { ...property });
    }
}
